import $ from 'jquery';
import 'bootstrap';
import { waitForDialogClose } from '@shared/DialogClose';
import { HttpRequestService } from '@shared/HttpRequestService';
export class Message {
    showCloseButton;
    content;
    title;
    callback;
    constructor(content, title, callback, showCloseButton) {
        this.showCloseButton = showCloseButton;
        this.content = content;
        this.title = title;
        this.callback = callback;
    }
}
class Builder {
    messages;
    fancyMsg;
    constructor(fancyMsg) {
        this.messages = [];
        this.fancyMsg = fancyMsg;
    }
    add(content, title, callback, showCloseButton) {
        this.addMessage(new Message(content, title, callback, showCloseButton));
        return this;
    }
    addMessage(message) {
        this.messages.push(message);
        return this;
    }
    showSequence() {
        showInternal(this.messages, this.fancyMsg);
    }
    ;
}
function showInternal(messages, fancyMsg) {
    const message = messages.shift();
    if (!message) {
        return;
    }
    fancyMsg.setContent(message.content, message.title, message.showCloseButton)
        .show();
    if (message.callback) {
        message.callback();
    }
    waitForDialogClose(fancyMsg.domIdSelector, () => {
        showInternal(messages, fancyMsg);
    });
}
export class FancyMsg {
    defaultLabel;
    msgContent;
    modalLabel;
    showCloseButton;
    domId;
    domIdSelector;
    constructor(msgContent) {
        this.defaultLabel = 'LTL Alert Message';
        this.msgContent = msgContent ?? '';
        this.modalLabel = this.defaultLabel;
        this.showCloseButton = true;
        this.domId = 'modal3434';
        this.domIdSelector = `#${this.domId}`;
    }
    async init() {
        await this.loadSkeleton();
    }
    createMessage(content, title, callback, showCloseButton) {
        return new Message(content, title, callback, showCloseButton);
    }
    onDialogClose(callback) {
        waitForDialogClose(this.domIdSelector, callback);
    }
    builder() {
        return new Builder(this);
    }
    setContent(msgContent, modalLabel, showCloseButton) {
        this.msgContent = msgContent;
        this.modalLabel = modalLabel || this.defaultLabel;
        this.showCloseButton = showCloseButton !== false;
        return this;
    }
    async loadSkeleton() {
        const targetUrl = '/Shared/Modal/Skeleton';
        const data = { domId: this.domId };
        const response = await HttpRequestService.getRequest(targetUrl, data);
        const htmlContent = await response.text();
        $(this.domIdSelector).remove();
        $(document.body).prepend(htmlContent);
        return this;
    }
    update() {
        $('#modalBodyContainer').html(this.msgContent);
        $('#modalLabel').html(this.modalLabel);
        if (this.showCloseButton) {
            $('.modalCloseButton').show();
        }
        else {
            $('.modalCloseButton').hide();
        }
    }
    show() {
        this.update();
        $(this.domIdSelector).modal('show');
        return this;
    }
    close() {
        $(this.domIdSelector).modal('hide');
        return this;
    }
}
